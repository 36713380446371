import React from "react";
import { useTranslation } from "react-i18next";

import { LINKED_IN, SKEY_MAIL, SKEY_SUPPORT } from "@constants/constants";
import { socialLinks } from "@constants/socialLinks";

import Link from "@components/toolkit/Link/Link";
import SocialIconLink from "./SocialIconLink/SocialIconLink";
import TelegramChannels from "./TelegramChannels/TelegramChannels";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation(["layout"]);

  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-main">
        <div className="main-content">
          <p className="p1 white-1">
            {t("layout:footer.ourTeam.description")}{" "}
            <Link href={LINKED_IN} target="_blank" title={t("layout:footer.ourTeam.link.title")} className="fs-5">
              {t("layout:footer.ourTeam.link.label")}
            </Link>
          </p>

          <div className="main-row">
            <div className="social-media">
              <p className="p1">{t("layout:footer.followUs.title")}</p>

              <div className="social-list">
                {socialLinks.map(({ url, icon }, index) => {
                  return (
                    <SocialIconLink key={index} href={url}>
                      {icon}
                    </SocialIconLink>
                  );
                })}

                <TelegramChannels />
              </div>
            </div>

            <div className="legal-disclaimer">
              <p className="p1 white-1">{t("layout:footer.legalDisclaimer.title")}</p>
              <p className="p0 white-1">
                {t("layout:footer.legalDisclaimer.subtitle")}
                <br />
                BoTGlobal OÜ <br />
                Maakri tn 19/1-7K Tallinn <br />
                10145 Estonia
              </p>
            </div>

            <div className="contact">
              <p className="p1 white-1">{t("layout:footer.contact.title")}</p>

              <div>
                <p className="p0 white-1">{t("layout:footer.contact.information")}</p>
                <Link href={`mailto:${SKEY_MAIL}`} className="p0">
                  {SKEY_MAIL}
                </Link>
              </div>

              <div>
                <p className="p0 white-1">{t("layout:footer.contact.integration")}</p>
                <Link href={`mailto:${SKEY_SUPPORT}`} className="p0">
                  {SKEY_SUPPORT}
                </Link>
              </div>
            </div>

            <div className="project-reporting">
              <p className="p1 white-1">{t("layout:footer.projectReporting.title")}</p>
              <img src="/images/layouts/europe-flag.png" alt="europe flag" width={54} height={36} />
              <p className="p0 white-1">{t("layout:footer.projectReporting.description")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-copyright">
        <div className="copyright-content">
          <p className="p0">{t("layout:copyright", { year })}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
